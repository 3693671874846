<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <!-- Custom field modal -->
        <div class="modal fade custom-fields show" id="new-pipeline-template" style="display: block;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <form action="">
                    <div class="modal-content" v-on-click-away="close">
                        <div class="modal-header">
                            <h4 class="modal-title text-uppercase">{{ $t("Job templates") }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="close()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body" style="min-height: 300px;">
                            <div class="form-row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <input type="text" id="job-title" ref="job_title" v-model="form.title" :placeholder="$t('search job template')" class="form-control" maxlength="150" @keyup="searchTemplates(form.title)" autofocus/>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <multiselect
                                                v-model="industry"
                                                :options="industries"
                                                placeholder="Select industry"
                                                track-by="id"
                                                label="name"
                                                @open="findIndustry"
                                                deselect-label=""
                                                select-label=""
                                                @search-change="getIndustries"
                                                @input="searchTemplates"

                                        ></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="content-area__body" style="padding: 0; margin-top: 10px;">
                                <div class="data-table user-table" v-if="!isEmpty">
                                    <div class="table-wrap">
                                        <div class="table table-modal" style="border-spacing: 2px 15px;">
                                            <div class="table__row pinter-select my-0 py-0" v-for="(template, index) in jobTemplates" @click.prevent="selectTemplate(index)">
                                                <div class="table-cell pinter-select">
                                                    <a href="#" style="font-size: 1rem;">{{ template.title }}</a>
                                                </div>
                                                <div class="table-cell text-right my-0 py-0">
                                                    <a href="#" class="form-note color-primary">{{ $t("select") }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6 v-else style="color: #ff5f74;" class="mt-2">{{ $t('No templates found.') }}</h6>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <button class="button semi-button-info" data-dismiss="modal" @click.prevent="close">{{ $t('back') }}</button>
                            <pagination :pagination="paginationData" @paginate="paginate"></pagination>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import client from "../../../app/api/Client";
import {debounce} from "lodash";
import Multiselect from "vue-multiselect";
import {getCompanyTypes} from "../../../app/api/CommonRequest"
import {mapState} from "vuex";
import Pagination from "../../pagination/Pagination";


export default {
    name: 'SearchJobTemplate',
    components: {
        Multiselect,
        Pagination
    },
    data() {
        return {
            jobTemplates: [],
            selectedJobTemplate: null,
            isLoading: false,
            form: {
                title: '',
            },
            industry: null,
            industries: [],
            paginationData: {},
            page: 1
        }

    },
    computed: {
        ...mapState(['company']),
        isEmpty() {
            return this.jobTemplates.length === 0;
        }
    },
    methods: {
        close() {
            this.$emit('closeJobTemplateModal');
        },
        async paginate(page) {
            this.page = page ?? 1;
            await this.getTemplates();
        },
        async getTemplates(typeId) {
            try {
                let {data} = await client().get(`/job/templates`, {
                    params: {
                        title: this.form.title,
                        industry_id: this.industry ? this.industry.id : null,
                        type: typeId,
                        page: this.page
                    }
                });
                this.jobTemplates = data.data.templates.data;
                if (data.data.industry) {
                    this.industry = data.data.industry;
                }
                this.paginationData = data.data.templates;
            } catch (e) {
            }
        },
        getIndustries: debounce(function (searchQuery) {
            this.findIndustry(searchQuery);
        }, 1200),
        async findIndustry(searchQuery) {
            let {data} = await getCompanyTypes(searchQuery);
            this.industries = data;
            this.industries.unshift({
                id: null,
                name: '{All Templates}'
            })
        },
        selectTemplate(index) {
            this.selectedJobTemplate = this.jobTemplates[index];
            this.$emit('templateSelected', this.selectedJobTemplate);
        },
        searchTemplates: debounce(function (searchQuery) {
            this.page = 1;
            this.getTemplates();
        }, 500),

    },
    async created() {

        await this.getTemplates(this.company.companyTypeId ?? null);
    },
    mounted() {
        this.$refs.job_title.focus();
    }
};
</script>

<style scoped>

    .table-modal .table-cell {
        padding: 10px !important;
        background: #f8f8fd !important;
    }

    .pinter-select {
        cursor: pointer;
    }

    @media (max-width: 767.98px) {
        .table-modal .table__row {
            margin-bottom: 10px !important;
        }

        .table-modal .table__row .table-cell {
            flex: 0 0 50%;
        }
    }

</style>
